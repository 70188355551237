// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

	MobileMenu: {
		init: function() {
            FE.MobileMenu.indicateSubMenu();
			$('.nav-mobile-icon').click(function() {
                $('.nav-mobile').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-mobile > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                });
            });
        }
	},

    HeroSlider: {
        init: function() {
            $('.ce-slider .ce-slider-container').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: false,
                infinite: true,
                speed: 1000,
                fade: false,
                slide: 'figure',
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 5000,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },

    HistoryListSlider: {
	    init: function () {
            $('.history-list-view-special .slider').slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                slide: 'figure',
                dots: false,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ],
                focusOnSelect: false
            });
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		//FE.MobileMenu.init();

        // clone images to display as thumbs
        $('.history-detail-image .image').clone().appendTo('.history-image-thumbs');

        // init slider
        /*
        $('.history-detail-view-special .history-detail-image').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            infinite: true,
            speed: 1000,
            fade: true,
            slide: 'div',
            cssEase: 'linear',
            autoplay: false,
            centerMode: false,
            focusOnSelect: false
        });
        */

        //$('.history-image-thumbs .image').imgLiquid();

        // make thumbs clickable and select big size image
        //$('.history-image-thumbs .image').on('click', function() {
        //    $('.history-detail-image').slickGoTo( parseInt($(this).index()) );
        //});

        // init timeline if available
        if ( $('.page-id-3').length ) {
            initTimeline();
        }

        // init historical buildings slider
        var historyListSlider = $('.history-list-view-special .slider');

        if ( $(historyListSlider).length ) {

            var viewportWidth = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            if ( parseInt(viewportWidth) > 800 ) {
                FE.HistoryListSlider.init();
            } else {
                $(historyListSlider).addClass('slider-not-initialized');
            }

        }

	});

}(jQuery, window, document));